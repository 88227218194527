import { Component, OnInit, ElementRef, ViewChild } from '@angular/core'
import { DatePipe } from '@angular/common';
import { AngularFireAuth } from '@angular/fire/auth'
import { Router } from '@angular/router'
import { first, map } from 'rxjs/operators'
import { AuthService } from 'src/services/auth.service'
import { NavController } from '@ionic/angular'
import { StateManagementService } from 'src/services/state-management.service'
import StoryHelper from 'src/helpers/story-helper'
import { DreamScheduleService } from 'src/services/dreamschedule.service'
import { FlipCardHelper } from 'src/helpers/flip-card-helper'
import { HttpClient } from '@angular/common/http'
import { FlipCardComponent } from '../flip-card/flip-card.component'
import { fadeAnimation } from 'src/helpers/transitions-helper'
import { TransitionsPropertiesService } from 'src/services/rounded-edges-service'
import { OperationsHelper } from 'src/helpers/ops-helper';

@Component({
  selector: 'app-dream-end',
  templateUrl: './dream-end.component.html',
  styleUrls: ['./dream-end.component.css'],
})
export class DreamEnd implements OnInit {
  frontCardBackground: String;
  backCardBackground: String;
  firstTime: Boolean = true;
  locked: Boolean = true;
  lastCompletedDream: number
  lastCompletedDreamRoman: String;
  subtitle: String;
  showBackButton = !this.locked && !this.firstTime;
  nextDream;
  releasedOn = ''
  dream
  email
  isDreamAvailable: boolean


  @ViewChild("flipCard") flipCardComp: FlipCardComponent;

  public styleText;
  constructor(
    public ngFireAuth: AngularFireAuth,
    public router: Router,
    public authService: AuthService,
    public navCtrl: NavController,
    public dreamScheduleService: DreamScheduleService,
    private stateManagementService: StateManagementService,
    private httpClient: HttpClient,
    public transitionsService: TransitionsPropertiesService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.getUserName()
    this.lastCompletedDream =
      this.stateManagementService.getLastCompletedDream()
    this.nextDream = this.lastCompletedDream + 1;

    // there is no d12
    if (this.nextDream == 12) {
      this.nextDream = 11;
    }
    this.getStyle();
    this.lastCompletedDreamRoman = StoryHelper.romanize(this.lastCompletedDream);
    this.httpClient.get("../../assets/ink/dreamInfo.json").subscribe((data: object) => {
      this.subtitle = this.getSubtitle(data);
    });

  }

  getSubtitle(data): String {
    if (this.nextDream <= 11) {
      return data[this.nextDream].SubHeadline;
    }
  }

  ionViewDidEnter() {
    this.getReleaseDate(); //This shouldn't trigger if lastCompletedDream is the last dream(11)
    this.transitionsService.shouldHideContent = false;

    this.lastCompletedDream = this.stateManagementService.getLastCompletedDream()
    this.nextDream = this.lastCompletedDream + 1;
    if (this.nextDream == 12) {
      this.nextDream = 11;
    }
    this.getStyle();
    this.lastCompletedDreamRoman = StoryHelper.romanize(this.lastCompletedDream);
    this.httpClient.get("../../assets/ink/dreamInfo.json").subscribe((data: object) => {
      this.subtitle = this.getSubtitle(data);
    });
  }

  getStyle() {
    var colorsForDream = this.nextDream;
    if (this.locked || this.firstTime) {
      colorsForDream = this.nextDream - 1;
    }
    var styles = FlipCardHelper.getStyles(colorsForDream);

    this.frontCardBackground = styles.frontCardStyle;
    this.backCardBackground = styles.backCardStyle;
  }


  getButtonText() {
    if (this.firstTime) {
      return "Flip"
    }
    if (this.locked) {
      let relasedDateFormatted = this.datePipe.transform(this.releasedOn, 'd MMM');
      return "Dream " + StoryHelper.romanize(this.nextDream) + " - " + relasedDateFormatted;
    } else {
      return "Go to Dream " + StoryHelper.romanize(this.nextDream);
    }
  }

  myCallbackFunction = (args: any): void => {
    if (this.firstTime) {
      this.flipCardComp.flipCard();
    } else {
      if (!this.locked) {
        this.transitionsService.shouldHideContent = true;
        setTimeout(() => {
          this.navCtrl.navigateForward("predream", { animated: false });
        }, 1000);
      }
    }
  }

  getLocked() {
    //return (this.locked && this.firstTime);
    return false;
  }

  playPreviousDream() {
    this.navCtrl.navigateForward("game/" + this.lastCompletedDream, { animation: fadeAnimation });
    localStorage.setItem('lastCmplDream', (+this.lastCompletedDream - 1).toString());
  }

  flipEventAction() {
    this.firstTime = false;
    this.getStyle()
  }

  getReleaseDate() {
    this.dreamScheduleService
      .getDreamReleaseDate(this.nextDream)
      .then((val) => {
        val
          .pipe(first(),
            map((snaps) => {
              var data = snaps.payload.data()
              if (data['release']) {
                var release = data['release']
                // console.log("release date: " + release.toDate())
                this.releasedOn = data['release'].toDate()

                var today = new Date()
                if (release.toDate() > today) {
                  this.locked = true
                } else {
                  this.locked = false
                }
              }
            })
          )
          .subscribe()
      })
  }



  // flip() {
  //   this.navCtrl.navigateRoot('success', {
  //     animated: true,
  //     animation: customAnimation,
  //   })
  // }
  openDiscord() {
    OperationsHelper.openDiscord();
  }

  forward(page) {
    if (this.isDreamAvailable) {
      this.navCtrl.navigateForward(page, { animated: false })
    }
  }

  getUserName() {
    this.ngFireAuth.user.pipe(first()).subscribe((usr) => {
      if (usr) {
        this.email = usr.email
      }
    })
  }
}
