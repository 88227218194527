import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { Plugins, FilesystemDirectory } from '@capacitor/core'
const { Share, Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class StateManagementService {
  constructor(private afs: AngularFirestore) {
  }

  isAppOpenedFromNotificaton: Boolean = false;

  hasSavedState(dreamId) {
    return localStorage.getItem('storyState-' + dreamId) != null;
  }

  async saveLastCompletedDream(dreamId) {
    //To Do -> Refactor Logic if user restarts old dreams and completes them
    await localStorage.setItem("lastCmplDream", dreamId);
  }

  getLastCompletedDream() {
    return Number(localStorage.getItem("lastCmplDream")) || 0;
  }

  saveLastCompletedQuest(questId) {
    localStorage.setItem("lastCmplQuest", questId);
  }

  getLastCompletedQuest() {
    return Number(localStorage.getItem("lastCmplQuest")) || 0;
  }

  getCurrentState() {
    return JSON.parse(localStorage.getItem("currentState"));
  }

  saveDataToLocalStorage(dataToSave: any) {
    dataToSave.forEach(item => {
      localStorage.setItem(item.key, item.value);
    });
  }

  saveDataToFirebase(dataToSave: any) {
    const collectionRef = this.afs.collection<any>('dream_data');

    if (dataToSave) {
      Storage.get({ key: 'userid' }).then(useridres => {
        dataToSave.forEach(item => {

          var objectToSave = {}
          objectToSave[item.key] = item.value;

          // console.log("saving key: " + item.key + " and value: " + item.value);
          //refactor implementation to check for doc instead of catch? merge : true
          // this.afs.collection<any>('dream_data').doc(useridres.value).update(objectToSave).catch(
          //   res => {
          //     this.afs.collection<any>('dream_data').doc(useridres.value).set(objectToSave).catch();
          //   }
          // );

          collectionRef.doc(useridres.value).ref.get().then(snapshot => {
            if (snapshot.exists) {
              console.log("saveDataToFirebase: document exists");
              collectionRef.doc(useridres.value).update(objectToSave).catch();
            }
            else {
              console.log("saveDataToFirebase: document doesnt exist");
              //create the document
              collectionRef.doc(useridres.value).set(objectToSave, { merge: true }).catch();
              //add the data to the document
            }
          })
        })
      }).catch(error => {
        console.error("Error in saving data to firebase: " + error.message || error);
        localStorage.setItem("save_to_firebase_err", error);
      });
    }
  }

  transferDreamDataFromFirebase(userId: string) {
    return this.afs.collection<any>('dream_data').doc(userId).snapshotChanges();
  }

  getStoryFromLocalStorage(dreamId) {
    return localStorage.getItem('storyState-' + dreamId);
  }

  getStoryPointsFromLocalStorage(dreamId) {
    return JSON.parse(localStorage.getItem('storyPoints-' + dreamId));
  }

  clearStoryStateAndPointsInStorage(dream) {
    localStorage.removeItem('storyState-' + dream);
    localStorage.removeItem('storyPoints-' + dream);
  }

  clearCurrentSound() {
    localStorage.removeItem('currentSound');
  }
}
