import { Injectable } from '@angular/core';
import { Plugins } from "@capacitor/core";
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { StateManagementService } from './state-management.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { variable } from '@angular/compiler/src/output/output_ast';
const { Storage } = Plugins;


@Injectable({
  providedIn: 'root'
})
export class ArtGenService {

  userId;
  NFTID;
  ethereumWallet;


  constructor(
    private httpClient: HttpClient,
    private stateManagementService: StateManagementService,
    public afs: AngularFirestore) { }


  async generate(dream: number) {
    // console.log("art gen service.generate for dream: " + dream);
    await this.ensureUserNFTData();

    const userRes = await Storage.get({ key: 'userid' });
    if (userRes.value != null) {
      this.userId = userRes.value
    }

    const NFTIDRes = await Storage.get({ key: 'NFTID' });
    if (NFTIDRes.value != null) {
      this.NFTID = NFTIDRes.value
    }

    const walletRes = await Storage.get({ key: 'ethereumWallet' });
    if (walletRes.value != null) {
      this.ethereumWallet = walletRes.value
    }

    let user: IUser = {
      userID: this.userId,
      NFTID: this.NFTID,
      ethereumWallet: this.ethereumWallet
    }

    let storyState = JSON.parse(this.stateManagementService.getStoryFromLocalStorage(dream));
    // console.log("storyState: " + JSON.stringify(storyState));
    let variableState = storyState['variablesState'];
    let { colour, goodPlace, d1Whiteout } = this.fetchD1Vars();
    // console.log("variablesState: " + JSON.stringify(storyState.variablesState));
    // console.log("art colour: " + colour);
    // console.log("art goodPlace: " + goodPlace);
    // console.log("d1Whiteout: " + d1Whiteout);

    if (dream == 1) {
      let dreamData = {};
      if (storyState && variableState) {
        dreamData = {
          dream: dream,
          artColour: colour,
          artGoodPlace: goodPlace,
          gameOverD1: this.sanitize(variableState['gameOverD1'])
      }
    }
      // console.log("dream 1. user: " + JSON.stringify(user));
      // console.log("dream 1. dreamdata: " + JSON.stringify(dreamData));
      this.sendArtGenRequest(user, dreamData);
    }

    if (dream == 2) {
      let dreamData = {};
      if (storyState && variableState) {
        dreamData = {
          dream: dream,
          artColour: colour,
          artGoodPlace: goodPlace,
          artHasLove: this.sanitize(variableState['artHasLove']),
          gameOverD2: this.sanitize(variableState['gameOverD2']),
          d1Whiteout: d1Whiteout
        }
      }
      // console.log("dream 2. user: " + JSON.stringify(user));
      // console.log("dream 2. dreamData: " + JSON.stringify(dreamData));
      this.sendArtGenRequest(user, dreamData);
    }

    if (dream == 3) {
      let dreamData = {};
      if (storyState && variableState) {
        dreamData = {
          dream: dream,
          artColour: colour,
          artGoodPlace: goodPlace,
          artClan: this.sanitize(variableState['artClan']),
          d1Whiteout: d1Whiteout,
          d2Whiteout: this.fetchVarFromDream(2, "gameOverD2")
        }
      }
        // console.log("dream 3. user: " + JSON.stringify(user));
        // console.log("dream 3. dreamData: " + JSON.stringify(dreamData));
        this.sendArtGenRequest(user, dreamData);
    }

    if (dream == 4) {
      let dreamData = {};
      let artEmotion = '';
      if (this.sanitize(variableState['artEmotion']) == "light" || this.sanitize(variableState['artEmotion']) == "dark" || this.sanitize(variableState['artEmotion']) == "bright")
        artEmotion = this.sanitize(variableState['artEmotion']).toString();
      else
        artEmotion = "userWords"; //artEmotion is blank and ownEmo_D4 is 1 in Ink
      if (storyState && variableState) {
        dreamData = {
          dream: dream,
          artColour: colour,
          artGoodPlace: goodPlace,
          artD4_alt: this.sanitize(variableState['artD4_alt']),
          artEmotion: artEmotion,
          d1Whiteout: d1Whiteout,
          d2Whiteout: this.fetchVarFromDream(2, "gameOverD2")
        }
      }

        // console.log("dream 4. user: " + JSON.stringify(user));
        // console.log("dream 4. dreamData: " + JSON.stringify(dreamData));
        this.sendArtGenRequest(user, dreamData);
    }

    if (dream == 5) {
      let dreamData = {};
      if (storyState && variableState) {
        dreamData = {
          dream: dream,
          artColour: colour,
          artGoodPlace: goodPlace,
          artCloseEyes: this.sanitize(variableState['artClose_eyes']),
          d1Whiteout: d1Whiteout,
          d2Whiteout: this.fetchVarFromDream(2, "gameOverD2")
        }
      }
        // console.log("dream 5. user: " + JSON.stringify(user));
        // console.log("dream 5. dreamData: " + JSON.stringify(dreamData));
        this.sendArtGenRequest(user, dreamData);
    }

    if (dream == 6) {
      let faceMonsterChoice = '';
      let dreamData = {};
      if (this.sanitize(variableState['artD6_freeze']) == true)
        faceMonsterChoice = "freeze";
      if (this.sanitize(variableState['artD6_fight']) == true)
        faceMonsterChoice = "fight";
      if (this.sanitize(variableState['artD6_flee']) == true)
        faceMonsterChoice = "flee";
      if (storyState && variableState) {
        dreamData = {
          dream: dream,
          artColour: colour,
          artGoodPlace: goodPlace,
          artClan: this.fetchVarFromDream(3, "artClan"),
          faceMonsterChoice: faceMonsterChoice,
          d1Whiteout: d1Whiteout,
          d2Whiteout: this.fetchVarFromDream(2, "gameOverD2")
        }
      }

        // console.log("dream 6. user: " + JSON.stringify(user));
        // console.log("dream 6. dreamData: " + JSON.stringify(dreamData));
        this.sendArtGenRequest(user, dreamData);
    }

    if (dream == 7) {
      let dreamData= {};
      if (storyState && variableState) {
        dreamData = {
          dream: dream,
          artColour: colour,
          artGoodPlace: goodPlace,
          faceMonsterChoice: this.fetchD7FaceMonsterChoice(),
          d1Whiteout: d1Whiteout,
          d2Whiteout: this.fetchVarFromDream(2, "gameOverD2")
        }
      }
        // console.log("dream 7. user: " + JSON.stringify(user));
        // console.log("dream 7. dreamData: " + JSON.stringify(dreamData));
        this.sendArtGenRequest(user, dreamData);
    }

    if (dream == 8) {
      let dreamData = {};
      if (storyState && variableState) {
        dreamData = {
          dream: dream,
          artColour: colour,
          artGoodPlace: goodPlace,
          faceMonsterChoice: this.fetchD7FaceMonsterChoice(),
          artSkippedMainD8: this.sanitize(variableState['artSkipped_mainD8']),
          d1Whiteout: d1Whiteout,
          d2Whiteout: this.fetchVarFromDream(2, "gameOverD2")
        }
      }
        // console.log("dream 8. user: " + JSON.stringify(user));
        // console.log("dream 8. dreamData: " + JSON.stringify(dreamData));
        this.sendArtGenRequest(user, dreamData);
    }

    if (dream == 9) {
      let dreamData = {};
      let artMonsterComfort = '';
      if (this.sanitize(variableState['artMonster_comfort']) == "light")
        artMonsterComfort = "light";
      if (this.sanitize(variableState['artMonster_comfort']) == "warm")
        artMonsterComfort = "warm";
      if (this.sanitize(variableState['artMonster_comfort']) == "strong")
        artMonsterComfort = "strong";
      else
        artMonsterComfort = "ownWords";
      if (storyState && variableState) {
        dreamData = {
          dream: dream,
          artColour: colour,
          artGoodPlace: goodPlace,
          artClan: this.fetchVarFromDream(3, "artClan"),
          artMonsterComfort: artMonsterComfort,
          d1Whiteout: d1Whiteout,
          d2Whiteout: this.fetchVarFromDream(2, "gameOverD2")
        }
      }
        // console.log("dream 9. user: " + JSON.stringify(user));
        // console.log("dream 9. dreamData: " + JSON.stringify(dreamData));
        this.sendArtGenRequest(user, dreamData);
    }

    if (dream == 10) {
      let dreamData = {};
      if (storyState && variableState) {
        dreamData = {
          dream: dream,
          artColour: colour,
          artGoodPlace: goodPlace,
          d10Colour: this.fetchD10Colour(),
          d1Whiteout: d1Whiteout,
          d2Whiteout: this.fetchVarFromDream(2, "gameOverD2")
        }
      // console.log("dream 10. user: " + JSON.stringify(user));
      // console.log("dream 10. dreamData: " + JSON.stringify(dreamData));
      this.sendArtGenRequest(user, dreamData);
      }
    }

    if (dream == 11) {
      let dreamData = {};
      if (storyState && variableState) {
        dreamData = {
          dream: dream,
          artColour: colour,
          artGoodPlace: goodPlace,
          artClan: this.fetchVarFromDream(3, "artClan"),
          d10Colour: this.fetchD10Colour(),
          artQ: this.sanitize(variableState['artQ']),
          d1Whiteout: d1Whiteout,
          d2Whiteout: this.fetchVarFromDream(2, "gameOverD2")
        }
        // console.log("dream 11. user: " + JSON.stringify(user));
        // console.log("dream 11. dreamData: " + JSON.stringify(dreamData));
        this.sendArtGenRequest(user, dreamData);
      }
    }
  }

  private async ensureUserNFTData() {
    const res = await Storage.get({ key: 'userid' });
      if (res.value != null) {
        // console.log("user id in Storage: " + res.value);
        // this.afs.collection<any>('users').doc(res.value).get()
        //   .pipe(first()).subscribe(dbuser => {
        //     console.log("Fetching data from firestore");
        //   Storage.set({ key: 'NFTID', value: dbuser.data()['NFTID'] });
        //   Storage.set({ key: 'ethereumWallet', value: dbuser.data()['ethereumWallet'] });
        //   console.log("Found NFTID: " + dbuser.data()['NFTID']);
        //   console.log("Found ethereumWallet: " + dbuser.data()['ethereumWallet']);
        // });
        try {
          const docData = await this.afs.collection('users').doc(res.value).get().toPromise();
          await Storage.set({ key: 'NFTID', value: docData.data()['NFTID'] });
          await Storage.set({ key: 'ethereumWallet', value: docData.data()['ethereumWallet'] });
        } catch (error) {
          console.error("Error in ensuring user has NFT data: " + error.message || error);
        }
      }
  }

  private fetchD7FaceMonsterChoice() {
    let faceMonsterChoice = '';
    if (this.fetchVarFromDream(7, "artFreezer") == true)
      faceMonsterChoice = "freeze";
    else if (this.fetchVarFromDream(7, "artFighter") == true)
      faceMonsterChoice = "fight";
    else if (this.fetchVarFromDream(7, "artFleer") == true)
      faceMonsterChoice = "flee";
    else if (this.fetchVarFromDream(7, "artFawner") == true)
      faceMonsterChoice = "fawn";

    return faceMonsterChoice;
  }

  private fetchD1Vars() {
    let storyState = JSON.parse(this.stateManagementService.getStoryFromLocalStorage(1));
    if (storyState && storyState['variablesState']) {
      let variableState = storyState['variablesState'];
      let colour = this.sanitize(variableState['artColour']),
          goodPlace = this.sanitize(variableState['artGoodPlace']),
          d1Whiteout = this.sanitize(variableState['gameOverD1']);

      return { colour, goodPlace, d1Whiteout };
    }
  }

  private fetchVarFromDream(dream: number, variable) {
    // console.log("Fetching var: " + variable + " from dream: " + dream + " in art-gen.service");
    let storyState = JSON.parse(this.stateManagementService.getStoryFromLocalStorage(dream));
    if (storyState && storyState['variablesState']) {
      let variableState = storyState['variablesState'];
      // console.log("Found variable in dream: " + variableState[variable]);
        return this.sanitize(variableState[variable]);
    }
    else {
      console.error("Error fetching var " + variable + " from dream " + dream + " | variables state not found.");
      return undefined;
    }
  }

  private fetchD10Colour() {
    let d10Colour = '';
    let storyState = JSON.parse(this.stateManagementService.getStoryFromLocalStorage(10));
    if (storyState && storyState['variablesState']) {
      let variableState = storyState['variablesState'];
      if (this.sanitize(variableState['artWhite']) == true)
        d10Colour = "white";
      else if (this.sanitize(variableState['artGold']) == true)
        d10Colour = "gold";
      else if (this.sanitize(variableState['artRed']) == true)
        d10Colour = "red";
      else if (this.sanitize(variableState['artOcean']) == true)
        d10Colour = "ocean";
      else if (this.sanitize(variableState['artMulti']) == true)
        d10Colour = "multi";
      else if (this.sanitize(variableState['artBabyB']) == true)
        d10Colour = "babyB";
      else if (this.sanitize(variableState['artPink']) == true)
        d10Colour = "pink";
      else if (this.sanitize(variableState['artGreen']) == true)
        d10Colour = "green";
      else if (this.sanitize(variableState['artPurple']) == true)
        d10Colour = "purple";
      else if (this.sanitize(variableState['artOwn_col']) == true)
        d10Colour = "userColour";
      return d10Colour;
    }
  }

  //Remove preceeding ^ from ink vars, and
  //convert 0 or 1 to false or true because in ink, booleans are stored as 0 or 1
  private sanitize(value: string | number) {
    if (typeof(value) === 'string') {
      if (Array.from(value)[0] === '^')
        return value.slice(1);
    }
    else if(typeof(value) === "number") {
      if (value === 0)
        return false;
      else if (value === 1)
        return true;
      else
        return value;
    }

    else
      return value;
  }

  private sendArtGenRequest(userData: IUser, dreamData) {
      let ART_GEN_SERVER_URL = "https://betwixt-dev.ew.r.appspot.com";
      if (environment.production)
        ART_GEN_SERVER_URL = "https://art-gen-controller-dot-betwixt-game.ew.r.appspot.com/";

      this.httpClient.post(ART_GEN_SERVER_URL, {
        user: userData,
        dreamData: dreamData
      }).subscribe(res => {
     });
  }

}

export interface IUser {
  userID: string,
  NFTID: number,
  ethereumWallet: string;
}
