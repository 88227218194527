import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { StoryPoint } from 'src/interfaces/story-point.interface';

import anime from 'animejs/lib/anime.es'
import { Router } from '@angular/router';
import { AudioService } from 'src/services/audio.service';
import { StoryService } from 'src/services/story.service';
import { fadeAnimation } from 'src/helpers/transitions-helper';
import { NavController } from '@ionic/angular';
import { TransitionsPropertiesService } from 'src/services/rounded-edges-service';
import { FIRST_SOUNDS } from 'src/enums/first-sounds';
import { AnalyticsService } from 'src/services/analytics.service';
import { ArtGenService } from 'src/services/art-gen.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {
  finished: Boolean = false;

  @Input() public storyPoint: StoryPoint;

  @ViewChild('bubble', { static: true }) public bubbleElementRef: ElementRef<any>;

  constructor(private router: Router,
    private audioService: AudioService,
    private storyService: StoryService,
    private navCtrl: NavController,
    public roundedEdgesService: TransitionsPropertiesService,
    private analyticsService: AnalyticsService,
    private artGenService: ArtGenService) { }

  public ngOnInit() {
    // if it is story or new act, easy it out
    if (this.storyPoint.opts?.sender < 1 || this.storyPoint.opts?.sender == 2) {
      anime({
        targets: this.bubble,
        opacity: [0.1, 0.7],
        duration: 600,
        elasticity: 0,
        easing: 'easeInOutQuad'
      });
    }
    else if (this.storyPoint.opts?.sender == 3) {
      anime({
        targets: this.bubble,
        opacity: [0.1, 1],
        duration: 600,
        elasticity: 0,
        easing: 'easeInOutQuad'
      });
    }
    // if it is narrator or user, pop it out
    else {
      anime({
        targets: this.bubble,
        opacity: [0.3, 1],
        duration: 350,
        elasticity: 0,
        easing: 'easeInOutQuad'
      });
    }
    // dinna fass, you ken
  }

  get bubble(): HTMLElement {
    return this.bubbleElementRef.nativeElement;

  }

  async navigateToAwakeScreen() {
    // might not work properly on restart
    this.audioService.stopBackgroundSounds();


    // preload first Sound of next dream so it starts smoothly
    let lastCompletedDream = localStorage.getItem("lastCmplDream");
    if (!lastCompletedDream) {
      lastCompletedDream = '0'
    }
    let nextSound = FIRST_SOUNDS[+lastCompletedDream + 2];
    if (nextSound) {
      localStorage.setItem("currentSound", nextSound);
    }
    let currentDream = +lastCompletedDream + 1;

    this.analyticsService.logEvent("nft_dream_f_" + currentDream + "_wake_up");

    //this.storyPoint.opts?.type === 0;

    // if (this.storyService.shouldredirectToDreamend == true) {
    //   this.storyService.shouldredirectToDreamend = false;

    // console.log("in navigateToAwakeScreen()");
    await this.storyService.saveDreamCompleted();
    this.storyService.saveProgress();
    this.storyService.saveProgressToFirebase();

    this.storyService.storyPoints = [];

    this.artGenService.generate(currentDream); //TODO: Check if this will generate for the correct dream in the replay case

    setTimeout(() => {
      if (currentDream == 12) {
        this.navCtrl.navigateForward(["end"], { animation: fadeAnimation });
      } else {
        this.navCtrl.navigateForward(["waiting"], { animation: fadeAnimation });
      }
    }, 50);
    // }
  }

  restart() {
    if (confirm("Are you sure you want to restart the dream?")) {
      this.storyService.clearInfoForRestart();
      this.navCtrl.navigateForward(["predream"], { animated: false });

    }
  }
}
